import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { MentionModule } from 'angular-mentions';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LayoutModule } from '@angular/cdk/layout';
import { ImgixAngularModule } from '@imgix/angular';
import { ClipboardModule } from '@angular/cdk/clipboard';


import { AppRoutingModule } from './app-routing.module';
import { StringToDatePipe } from './pipes/toDate';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material-modules';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminAuthGuardService, AuthGuardService } from './services/auth-guard.service';
import { WINDOW_PROVIDERS } from './services/window.service';
import { StripeModule } from 'stripe-angular';

import { HomeComponent } from './screens/home/home.component';
import { ButtonComponent } from './components/button/button.component';
import { PanelComponent } from './panel/panel.component';
import { SectionComponent } from './components/section/section.component';
import { ActivityStreamComponent } from './screens/activity-stream/activity-stream.component';
import { LoginWithProviderComponent } from './components/login-with-provider/login-with-provider.component';
import { MyNotificationsComponent } from './screens/my-notifications/my-notifications.component';
import { NewsComponent } from './component/news/news.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignupComponent } from './screens/auth/signup/signup.component';
import { FullscreenImageComponent } from './components/fullscreen-image/fullscreen-image.component';
import { LoginComponent } from './screens/auth/login/login.component';
import { LoginProvidersDialogComponent } from './dialogs/login-providers-dialog/login-providers-dialog.component';
import { PreviewAvatarComponent } from './dialogs/preview-avatar/preview-avatar.component';
import { MenuComponent } from './components/menu/menu.component';
import { IconComponent } from './components/icon/icon.component';
import { VisionComponent } from './screens/vision/vision.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { ShareComponent } from './components/share/share.component';
import { DonateComponent } from './components/donate/donate.component';
import { OpenPostImageComponent } from './components/open-post-image/open-post-image.component';
import { OpenNewsPostComponent } from './components/open-news-post/open-news-post.component';
import { NewsOverlayComponent } from './components/news-overlay/news-overlay.component';
import { CommentsComponent } from './components/comments/comments.component';
import { NewsSocialSummaryComponent } from './components/news-social-summary/news-social-summary.component';
import { PromptComponent } from './dialogs/prompt/prompt.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { SupportComponent } from './screens/support/support.component';
import { ZoomQaComponent } from './screens/zoom-qa/zoom-qa.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
import { BackHeaderComponent } from './components/back-header/back-header.component';
import { AccountComponent } from './components/account/account.component';
import { AccountPaymentSourcesComponent } from './components/account/account-payment-sources/account-payment-sources.component';
import { AccountPersonalDetailsComponent } from './components/account/account-personal-details/account-personal-details.component';
import { RecoverPwdComponent } from './screens/auth/recover-pwd/recover-pwd.component';
import { ResetPwdComponent } from './screens/auth/reset-pwd/reset-pwd.component';
import { PrivacyPolicyComponent } from './screens/privacy-policy/privacy-policy.component';
import { Page404Component } from './screens/page404/page404.component';
import { MoreBusinessInfoComponent } from './screens/more-business-info/more-business-info.component';
import { RecipePreviewsComponent } from './components/recipe-previews/recipe-previews.component';
import { DonationReceiptComponent } from './screens/donation-receipt/donation-receipt.component';
import { ViewPostComponent } from './screens/view-post/view-post.component';
import { AllPostsComponent } from './screens/all-posts/all-posts.component';
import { ViewAuthorComponent } from './dialogs/view-author/view-author.component';
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';
import { ChooseAvatarComponent } from './dialogs/choose-avatar/choose-avatar.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ShowAddressComponent } from './components/show-address/show-address.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { InViewDirective } from './directives/in-view.directive';
import { LightOnAHillCampMeetingComponent } from './screens/events/light-on-a-hill-camp-meeting/light-on-a-hill-camp-meeting.component';
import { AbassadorProgramComponent } from './components/abassador-program/abassador-program.component';
import { AmbassadorsComponent } from './screens/ambassadors/ambassadors.component';
import { MadisonHeaderNavComponent } from './components/madison-header-nav/madison-header-nav.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ButtonComponent,
    PanelComponent,
    SectionComponent,
    ActivityStreamComponent,
    LoginWithProviderComponent,
    LoginProvidersDialogComponent,
    MyNotificationsComponent,
    NewsComponent,
    FooterComponent,
    SignupComponent,
    FullscreenImageComponent,
    LoginComponent,
    PreviewAvatarComponent,
    MenuComponent,
    IconComponent,
    StringToDatePipe,
    VisionComponent,
    SubscribeComponent,
    ShareComponent,
    DonateComponent,
    OpenPostImageComponent,
    OpenNewsPostComponent,
    NewsOverlayComponent,
    CommentsComponent,
    NewsSocialSummaryComponent,
    PromptComponent,
    ConfirmationDialogComponent,
    ContactUsComponent,
    ZoomQaComponent,
    SupportComponent,
    BackHeaderComponent,
    AccountComponent,
    AccountPaymentSourcesComponent,
    AccountPersonalDetailsComponent,
    RecoverPwdComponent,
    ResetPwdComponent,
    PrivacyPolicyComponent,
    Page404Component,
    MoreBusinessInfoComponent,
    RecipePreviewsComponent,
    MenuComponent,
    DonationReceiptComponent,
    AllPostsComponent,
    ViewPostComponent,
    ViewAuthorComponent,
    LoginDialogComponent,
    ChooseAvatarComponent,
    AutoFocusDirective,
    InViewDirective,
    ShowAddressComponent,
    TimeAgoPipe,
    LightOnAHillCampMeetingComponent,
    AbassadorProgramComponent,
    AmbassadorsComponent,
    MadisonHeaderNavComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    LayoutModule,
    ColorPickerModule,
    // StripeModule.forRoot('pk_test_fH1TQydI71pUImt3hDr5bGMX00as18FK4P'),
    StripeModule.forRoot('pk_live_TevlenGYgSSw58e6AXbrbV8800iolj2NAo'),
    MentionModule,
    ImgixAngularModule.forRoot({
      domain: 'cdn2.tda.website',
      defaultImgixParams: {
        auto: 'format,compress',
      },
    }),
    ClipboardModule,
  ],
  providers: [AdminAuthGuardService, AuthGuardService, WINDOW_PROVIDERS, StringToDatePipe, TimeAgoPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
