<app-back-header title="Make a Gift" back="" theme="charcoal"></app-back-header>

<div class="section" style="padding-bottom: 0;">
    <div class="wrapper">

        <p>Together we can establish a 21st-Century Madison College.</p>

        <p>Your investment in Madison Missions will pay off as this vision becomes reality, <strong>a truly self-supporting institution, training self-supporting missionaries.</strong></p>

        <h2>Donate with your card, once-off or monthly</h2>

        <app-donate></app-donate>
    </div>
</div>



<div id="happen" class="section" style="padding-top: 0;">

    <div class="wrapper">

        <h2>OR Donate via bank transfer</h2>
        <div class="grid">
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-165115.jpg?h=342&w=500&fit=crop&auto=format" alt="">
            <div class="txt">
                <p><strong>General:</strong><br/>
                Account Name: Madison Missions Aust Ltd<br/>
                BSB: 063 689<br/>
                Account Number: 1028 0545<br/>
                Reference: [Your Name]</p>
                <p><strong>Canvassing:</strong><br/>
                Account Name: Madison Canvassing<br/>
                BSB: 063 856<br/>
                Account Number: 1014 0684<br/>
                Reference: [Your Name]</p>
                <p><strong>The Maker Heals:</strong><br/>
                Account Name: The Maker Heals<br/>
                BSB: 063 689<br/>
                Account Number: 1029 1201<br/>
                Reference: [Your Name]</p>
            </div>
        </div>

        <p>Please <a routerLink="/contact" [queryParams]="{back: '/support'}">contact us</a> if you have any questions.</p>

        <p>We can also consider an interest-free or interest-bearing private loan. <a routerLink="/contact" [queryParams]="{back: '/support'}">Contact us</a> to discuss. </p>
        <p>Donations are not tax-deductible at this time. If you require making a tax-deductible donation, please discuss this with us.</p>

        <div class="logo-grid" style="margin-top: 50px;">
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison">
            </div>
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" alt="Eastward Missions" class="eastward">
            </div>
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The Maker Heals" class="makerheals">
            </div>
            <div class="img">
                <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive">
            </div>
        </div>

    </div>

</div>

<app-share></app-share>
<app-subscribe></app-subscribe>