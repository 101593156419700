<app-menu></app-menu>
<app-back-header title="Privacy Policy" back="" theme="charcoal"></app-back-header>
<div class="wrapper" style="max-width: 900px; padding: 20px; margin: auto;">

    <h1>Privacy Policy - Madison Website &amp; Mobile App(s)</h1>

    <p>This Privacy Policy governs the manner in which {{ siteTitle }}&nbsp;collects, uses, maintains and discloses information collected from users (each, a &quot;User&quot;) of the Staff Android/iOS &nbsp;app&nbsp;(&quot;App&quot;). This privacy
        policy applies to the App and all products and services offered by {{ siteTitle }}.<br /> &nbsp;
    </p>

    <p><strong>Personal identification information</strong></p>

    <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit the app, register, fill out a form, and in connection
        with other activities, services, features or resources we make available on our App. Users may be asked for, as appropriate, name, email address, mailing address, phone number.</p>

    <p>We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain
        App related activities.</p>

    <p>&nbsp;</p>

    <p><strong>Non-personal identification information</strong></p>

    <p>We may collect non-personal identification information about Users whenever they interact with our App. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection
        to our App, such as the operating system and the Internet service providers utilized and other similar information.</p>

    <p>&nbsp;</p>

    <p><strong>How we use collected information</strong></p>

    <p>{{ siteTitle }}&nbsp;collects and uses Users personal information for the following purposes:</p>

    <ul>
        <li><em>To improve customer service</em><br /> Your information helps us to more effectively respond to your customer service requests and support needs.</li>
        <li><em>To personalize user experience</em><br /> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our App.</li>
        <li><em>To improve our App</em><br /> We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
        <li><em>To process transactions</em><br /> We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary
            to provide the service.</li>
        <li><em>To administer a content, promotion, survey or other App feature</em><br /> To send Users information they agreed to receive about topics we think will be of interest to them.</li>
        <li><em>To send periodic emails</em><br />
            <br /> The email address Users provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If User decides
            to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed
            unsubscribe instructions at the bottom of each email or User may contact us via our App.</li>
    </ul>

    <p>&nbsp;</p>

    <p><strong>How we protect your information</strong></p>

    <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and
        data stored on our App.</p>

    <p>Sensitive and private data exchange between the App and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.&nbsp;<br /> &nbsp;
    </p>

    <p><strong>Compliance with children&#39;s online privacy protection act</strong></p>

    <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our App from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.<br
        /> &nbsp;
    </p>

    <p><strong>Changes to this privacy policy</strong></p>

    <p>{{ siteTitle }}&nbsp;has the discretion to update this privacy policy at any time. When we do, revise the updated date at the bottom of this page,. We encourage Users to frequently check this page for any changes to stay informed about how we are
        helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

    <p>&nbsp;</p>

    <p><strong>Your acceptance of these terms</strong></p>

    <p>By using this App, you signify your acceptance of this policy and&nbsp;terms of service. If you do not agree to this policy, please do not use our App. Your continued use of the App following the posting of changes to this policy will be deemed
        your acceptance of those changes.</p>

    <p>&nbsp;</p>

    <p><strong>Contacting us</strong></p>

    <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>

    <p><br /> {{ siteTitle }}</p>

    <p>{{ websiteDomain}}<br />
        <span style="line-height: normal;">{{ sitePhone1 }}</span><br style="line-height: normal;" />
        <span style="line-height: normal;">{{ siteEmail }}</span></p>

    <p>{{ siteAddress}}</p>

    <p>Last updated: 5/4/2020</p>

</div>
<app-footer></app-footer>