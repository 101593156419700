<app-back-header title="Business Activities" theme="grey50" back="/vision" fragment="business"></app-back-header>

<!-- COVID 19 -->
<app-panel theme="lightGrey" id="covid">
    <div class="wrapper">
        <div class="cols">
            <div class="a">
                <h1>New Direction - Beehive Services</h1>
                <p>COVID-19 shut-down our main source of income (Abide Wellness Retreat) on March 25, 2020. Job-keeper was not available to our volunteers & our application to the state government business fund was denied due to Abide's business classification.
                </p>
                <p> We commenced development of Beehive Community Services, starting with home-delivered meals, firewood & bulk vegan pies. Sales commenced on the 25th of April. We built a sophisticated website platform to sell meal packages, subscriptions
                    and other services.</p>
                <p>Websites:</p>
                <div class="btns">
                    <app-button label="Beehive.Services" (click)="open('https://beehive.services/')" theme="beehive" [border]="false"></app-button>
                    <br>
                    <app-button label="BeehiveTree.Services" (click)="open('https://beehivetree.services/')" theme="beehive" [border]="false"></app-button>
                </div>
            </div>
            <div class="b">
                <img src="https://cdn2.tda.website/beehive/20200529/0-02-07-cfcddd94d269c1ab2aba71fde7218e3fff2776424dab8ffdbbdc95519900099b-e3204f47.jpg?h=500&w=250&fit=crop&auto=format" alt="Beehive Firewood">
            </div>
        </div>
        <app-recipe-previews></app-recipe-previews>
    </div>
</app-panel>



<!-- ROADHOUSE -->
<app-panel theme="charcoal" id="roadhouse">
    <div class="wrapper">
        <h1>Black Spur <span class="grn">Roadhouse</span></h1>
        <p>Madison Missions purchased the Black Spur Roadhouse in early March. Settlement was March 9. The roadhouse is diagonally accross the road from the Abide facility and is an ideal location of employment for our team and opportunity for industry and
            connecting with the local community.</p>
        <p>This business has helped financially support our staff team during the COVID crisis and has great potential as a retail shop, grocery outlet, fuel station, restaurant/café and point of sale for Beehive Services. We own the business (which is a
            Metro Franchise), including all shop fittings and equipment. We do not own the property or infrastructure. We have a 7-year lease agreement with Metro, which is renewable.</p>
        <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-113655.jpg?h=720&w=900&fit=crop&auto=format" alt="Photos of the Black Spur Roadhouse">
    </div>
</app-panel>
<app-panel id="roadhouse2">
    <div class="wrapper">
        <img src="https://cdn2.tda.website/beehive/20200529/Black-Spur-Roadhouse-Logo.svg" alt="The Black Spur Roadhouse">
        <h1>Details</h1>
        <p>Pre-COVID the roadhouse would receive between 100 to 300 customers in a day.</p>
        <h2>Next Steps </h2>
        <p>Open cafe / restaurant. <br> - Replace dishwasher & oven<br> Re-modelling of inside and outside of facility [IN PROGRESS]<br> Explore Australia Post outlet/franchise<br> Utilise more space, extend grocery shop and health foods.<br> Promote Beehive
            Community Services and set up to take meal subscriptions.</p>
    </div>
</app-panel>

<div class="section-div"></div>

<!-- BEEHIVE MEALS -->
<app-panel class="business-service">
    <div class="wrapper">
        <div class="content">
            <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Food-Services.svg" alt="Beehive Food Services">

            <h2>Summary</h2>

            <p>Our meals are ready-to-eat 100% plant-based. Individual one-off meals start at $11.25 with large weekly meal subscriptions bringing the price down to as little as $8 per meal.</p>

            <p>We have initially targetted hot or frozen/chilled meals to several outer-eastern suburbs of Melbourne. </p>

            <h2>Research & Development</h2>

            <p>We have researched various options of chilled boxes & bags, necessary for meal delivery expansion.</p>

            <h2>Personnel</h2>

            <p>The leaders in this service are our wonderful volunteers: Jessica Granger & Kayla Reay. They are supported by several others on the property who assist with meal preparation.</p>

            <h2>Website</h2>
            <br>
            <app-button label="www.Beehive.Services" (click)="open('https://beehive.services/')" theme="beehive" [border]="false"></app-button>
        </div>
        <div class="pictures">
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-104826.jpg?h=828&w=500&fit=crop&auto=format" alt="">
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-104844.jpg?h=743&w=500&fit=crop&auto=format" alt="">
        </div>
    </div>
</app-panel>


<!-- BEEHIVE FIREWOOD -->
<app-panel class="business-service" theme="lightGrey">
    <div class="wrapper">
        <div class="content">
            <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Firewood.svg" alt="Beehive Firewood">

            <h2>Summary</h2>

            <p>Firewood is a very successful winter industry for us. We delivered 108 cubic meters or more than 50 tons in 3 weeks.</p>

            <p>Once we have stock we usually sell out within a couple of days. Sourcing dry logs has been a challenge.</p>

            <h2>Research &amp; Development</h2>

            <p>We have been researching options for sourcing large quantities of timber logs. We have exhausted many options but have some leads which we are following up on. We have considered a hydraulic wood splitting machine, but do not have need for
                this at the moment, while we have ample asisstance with splitting from the team here.</p>

            <h2>Personnel</h2>

            <p>The leaders in this service are our wonderful volunteers: Tim & Andy Hopkins. They are supported by several others on the property who assist with wood splitting and delivery.</p>

            <h2>Next Step:</h2>

            <p>Invest in a tipper-ute or small tipper-truck to enable delivery of larger quantities and safer deliveries.</p>

        </div>
        <div class="pictures">
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-110516.jpg?h=600&w=300&fit=crop&auto=format" alt="">
        </div>
    </div>
</app-panel>


<!-- BEEHIVE FIREWOOD -->
<app-panel class="business-service">
    <div class="wrapper">
        <div class="content">
            <img src="https://cdn2.tda.website/beehive/20200629/Beehive-Pies.svg" alt="Beehive Pies">

            <h2>Summary</h2>

            <p>We are selling about 300 vegan, veggie-curry pies per month. There is potential to expand and develop this business further.</p>
            <p> <i>"This is the best pie I've ever had. Thank you!"</i> - Roadhouse Customer</p>

            <h2>Research &amp; Development</h2>

            <p>We have put time into researching various aspects of pie production, mostly in two areas: Pastry making and shell and lid forming.</p>
            <p>We have purchased specialised pastry flour and vegan margarine (key ingredients to short-crust pastry) and have begun experimenting with making pastry.</p>
            <p>We are exploring this due to a nation-wide shortage of pastry post-COVID-19, and the difficulty in purchasing bulk vegan pastry at a good price.</p>
            <p> We have obtained quotes from different specialised pie-equipment manufactures with equipment ranging from $3k to $50k. </p>

            <h2>Personnel</h2>

            <p>The leaders in this service are our wonderful volunteers: Serah Roberts & Abby Placer.</p>

            <h2>Next Step:</h2>

            <p>Investing in a manually-operated pie-press enabling output of up to 1,000 pies per day.</p>
            <p>As the business grows we will consider building a baking facility with commercial semi-automatic pie equipment.</p>

        </div>
        <div class="pictures">
            <img src="https://cdn2.tda.website/beehive/20200608/pie.jpeg?h=180&w=300&fit=crop&auto=format" alt="">
            <br>
            <br>
            <br>
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-113253.jpg?h=306&w=500&fit=crop&auto=format" alt="">
        </div>
    </div>
</app-panel>

<!-- BEEHIVE TREE SERVICES  -->
<app-panel class="business-service development" theme="lightGrey">
    <div class="wrapper">
        <div class="content">
            <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Tree-Services.svg" alt="Beehive Tree Services">

            <h2>Summary</h2>

            <p>Under high-priority, we are preparing to launch "Beehive Tree Services", an arborist business to service most tree removal and maintenance needs.</p>
            <p>We have comissioned a professional logo, purchased a truck and chipper, and are working on a website, and promotion material. Beehive Tree Services will be operated by Harry Cole and Tim & Andy Hopkins. They have 15 years experience between
                them. Harry is fully-qualified and insured as an arborist and has been running a successful personal arborist business. The team is ready to go.</p>

            <h2>Personnel</h2>

            <p>The leaders in this service are: Harry Cole, Tim Hopkins, Andy Hopkins</p>

            <h2>Website</h2>
            <br>
            <app-button label="BeehiveTree.Services" (click)="open('https://beehivetree.services/')" theme="beehive" [border]="false"></app-button>

            <h2>Next Step:</h2>

            <p>Launch Beehive Tree Services website. [DONE]</p>
            <p>Purchase truck and chipper. [DONE]</p>
            <p>Repair trucks/chippers, roadworthy. [IN PROGRESS]</p>
            <p>Advertise. [IN PROGRESS]</p>

        </div>
    </div>
</app-panel>

<!-- BEEHIVE TREE SERVICES  -->
<app-panel class="business-service development">
    <div class="wrapper">
        <div class="content">
            <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Farm.svg" alt="Beehive Farm">

            <h2>Summary</h2>

            <p>When covid descended on the team, the garden was in sad neglect. Within a couple of weeks it underwent an incredible transformation.</p>
            <p>Now we are waiting for the plants to grow.</p>
            <p>A comprehensive crop plan is in place, with mentoring by Ian Mot, an Adventist soil scientist at Vital Soils (www.vitalsoils.com.au)</p>
            <p>Once produce is available we will promote home-delivered veggies, veggie-boxes, selling at markets, and at the roadhouse</p>

            <h2>Personnel</h2>

            <p>The leaders in this service are our wonderful volunteers: Ester Harmse & Beau Farawell.</p>

            <h2>Next Step:</h2>

            <p>Harvesting</p>
            <p>Marketing</p>
            <p>Further Agriculture and Development</p>

        </div>
    </div>
</app-panel>

<app-subscribe></app-subscribe>