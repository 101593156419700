<div id="vision-nav-pill" matRipple [ngClass]="{open: visionNavOpen}" (click)="openVisionNav()"></div>

<nav class="vision-nav" [ngClass]="{open: visionNavOpen}">
    <a routerLink="" [fragment]="" (click)="visionNavOpen = false; scrollToTop();" matRipple matRippleColor="#f36e2145">
        <img class="main-logo" src="https://cdn2.tda.website/beehive/20200607/Madison-Sq-lge.svg" alt="Madison Missions Australia Ltd. Logo" style="width: 30px; height: 30px;">
    </a>
    <a routerLink="" [id]="'link-' + nav.anchor" (click)="navigate(nav)" matRipple matRippleColor="#f36e2145" *ngFor="let nav of visionNav">{{ nav.label }}</a>
</nav>

<div class="overlay" [ngClass]="{open: visionNavOpen}" (click)="openVisionNav()"></div>

<div class="wrapper">

    <div id="homeContent">

        <!-- VISION  -->
        <app-panel id="vision">
            <h1>Our Vis<span class="grn">ion</span></h1>
            <h2>Healing · Education · Industry</h2>
            <p>A balanced approach to ministry, applying counsel & history</p>
            <div class="logo-grid">
                <div class="img">
                    <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison">
                </div>
                <div class="img">
                    <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" alt="Eastward Missions" class="eastward">
                </div>
                <div class="img">
                    <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The Maker Heals" class="makerheals">
                </div>
                <div class="img">
                    <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive">
                </div>
            </div>
        </app-panel>

        <!-- ELEMENTS OF VISION  -->
        <app-panel theme="charcoal" id="vision2">
            <div class="wrapper">
                <img src="https://cdn2.tda.website/beehive/20200607/Arrow-down-fat.svg" alt="Arrow Down" class="arrow-dwn ar1">
                <div class="grid">
                    <div class="box header">
                        Foundation on Bible & Spirit of Prophecy Counsel
                    </div>
                    <div class="box h b3">Training School</div>
                    <div class="box h b1">Health Ministry</div>
                    <div class="box h b2">Business Franchise</div>
                    <div class="box h b4">Youth Ministry</div>
                    <div class="box h b5">Evangelism Centre</div>
                    <div class="box h b6">Agriculture Hub</div>
                    <div class="box footer">
                        Salvation - Gospel Proclamation - Finishing the Work
                    </div>
                </div>
                <p>It is no small undertaking to carry out plans of this <br> proportion, in this country, in this generation.</p>
            </div>
        </app-panel>


        <!-- NARBETHONG FACILITY  -->
        <img src="https://cdn2.tda.website/beehive/20200607/aerialview-retreat.jpg?fp-x=0.37&fp-y=0.6&crop=focalpoint&h=500&w=1920&fit=crop&auto=format" id="aerial">


        <!-- MADISON QUOTE  -->
        <app-fullscreen-image class="quote1" src="https://cdn2.tda.website/beehive/20200607/MC-2018-168-Near-To-The-Heart-of-God-BTS-2.jpg?h=1080&w=1920&fit=crop&auto=format">
            <div class="content">
                <div class="inner">
                    <img src="https://cdn2.tda.website/beehive/20200607/Quote.svg">
                    <div class="txt">
                        <div class="main">Every possible means should be devised to establish schools of the Madison order... </div>
                        <div class="reference">- Spalding and Magan Collection, 423</div>
                    </div>
                </div>
            </div>
        </app-fullscreen-image>


        <!-- BUSINESS OVERVIEW -->
        <app-panel id="business">
            <div class="top-images">
                <div class="a">
                    <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services">
                </div>
                <div class="b">
                    <img src="https://cdn2.tda.website/beehive/20200607/Business-Directions.svg" alt="Business Directions">
                </div>
            </div>
            <h1>Business / <span class="grn">Franchise</span></h1>
            <p><small>Beehive Community Services is a trading name of Madison Missions Australia Ltd.</small></p>
            <p>Beehive aspires to be a franchise with different businesses.</p>
            <p>Beehive is based on a belief that self-supporting ministries should support themselves.</p>
            <p>Business training is essential for self-supporting missionaries.</p>
            <div class="cols">
                <div class="a">
                    <h2>Selling Now</h2>

                    <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Food-Services.svg" alt="Beehive Food Services">

                    <img src="https://cdn2.tda.website/beehive/20200629/Beehive-Pies.svg" alt="Beehive Pies">

                    <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Farm.svg" alt="Beehive Farm">

                </div>
                <div class="b">
                    <h2>&nbsp;</h2>
                    <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Tree-Services.svg" alt="Beehive Tree Services">

                    <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Firewood.svg" alt="Beehive Firewood">
                </div>
                <div class="c">
                    <h2>Incubating</h2>
                    <h3>
                        <img src="https://cdn2.tda.website/beehive/20200607/Incubating-cleaning.svg" alt="Cleaning Service">
                        <span>Cleaning Service</span>
                    </h3>
                    <h3>
                        <img src="https://cdn2.tda.website/beehive/20200607/Incubating-mower.svg" alt="Mowing Ser ice">
                        <span>Mowing Service</span>
                    </h3>
                    <h3>
                        <img src="https://cdn2.tda.website/beehive/20200607/Incubating-coaching.svg" alt="Coaching Service">
                        <span>Coaching Service</span>
                    </h3>
                    <h3>
                        <img src="https://cdn2.tda.website/beehive/20200607/Incubating-maintenance.svg" alt="Maintenance Service">
                        <span>Maintenance Service</span>
                    </h3>
                </div>
            </div>
        </app-panel>

        <!-- OUR WEBSITES -->
        <app-panel class="standard-section" theme="beehive" style="text-align: center;">
            <h1>Beehive Websites</h1>
            <p>Check them out!</p>
            <div class="btns">
                <app-button label="Beehive.Services" (click)="open('https://beehive.services/')" theme="beehive"></app-button>
                <br>
                <app-button label="BeehiveTree.Services" (click)="open('https://beehivetree.services/')" theme="beehive"></app-button>
            </div>
        </app-panel>

        <!-- ROADHOUSE -->
        <app-panel theme="lightGrey" id="roadhouse">
            <div class="wrapper" style="padding: 60px 20px;">
                <h1>Black Spur <span class="grn">Roadhouse</span></h1>
                <p>Madison Missions purchased the Black Spur Roadhouse in early March. We are excited at the opportunity this has provided. Read more below.</p>
                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-113655.jpg?h=720&w=900&fit=crop&auto=format" alt="Photos of the Black Spur Roadhouse">
            </div>
        </app-panel>

        <app-section>
            <div class="wrap" style="max-width: 600px; text-align: center; margin: auto;">
                <h1 style="margin-bottom: 30px;">More</h1>
                <p>Read about the Roadhouse &amp; Beehive Services.</p>
                <app-button label="Yes, Tell Me More" [border]="false" (click)="scrollTop()" theme="beehive" routerLink="/business"></app-button>
            </div>
        </app-section>

        <div class="section-div"></div>

        <!-- HEALTH MINISTRY - THE MAKER HEALS -->
        <app-fullscreen-image id="health" src="https://cdn2.tda.website/beehive/20200607/aerialview-retreat.jpg?fp-x=0.52&fp-y=0.51&fp-z=1.7&q=36&crop=focalpoint&h=1080&w=1920&fit=crop&auto=format">
            <h1>Health <span class="grn">Ministry</span></h1>
            <div class="content">
                <img src="https://cdn2.tda.website/beehive/20200608/The-Maker-Heals-White-png.png" alt="">
            </div>
        </app-fullscreen-image>


        <!-- MAKER HEALS PURPOSE AND PRINCIPLES -->
        <app-panel class="standard-section retreat-model">
            <div class="wrapper">
                <h1>Purpose</h1>
                <div class="health-section-points">
                    <p>
                        <span class="number">1</span>
                        <span class="txt">To proclaim the Three Angels' Message with the health message to: those who are sick, those who want to prevent sickness, those who want to help others who are sick.</span>
                    </p>
                    <p>
                        <span class="number">2</span>
                        <span class="txt">To lead people to receive Jesus as their personal Savior.</span>
                    </p>
                    <p>
                        <span class="number">3</span>
                        <span class="txt">To introduce the Creator God, His character, His plan for salvation, and His principles for healthy living.</span>
                    </p>
                    <p>
                        <span class="number">4</span>
                        <span class="txt">To provide health education that creates a change of lifestyle.</span>
                    </p>
                    <p>
                        <span class="number">5</span>
                        <span class="txt">To provide ideal conditions by which people can experience restoration physically, mentally, and spiritually.</span>
                    </p>
                </div>

                <h1 class="grn">Principles</h1>

                <div class="health-section-points">
                    <p>
                        <span class="number grn">1</span>
                        <span class="txt">God is our Creator, Savior, Healer, and Provider. As such, we depend fully upon Him to be our Healer. Treatment methods such as massage, hydrotherapy & herbs will be used sparingly and when needed. We do not want to lead people to believe that treatments alone have healed them. We point solely to Jesus Christ as Healer, the One who has given us His health laws, His natural healing power, the Restorer of body, mind, and soul. For this reason, the practices of the sanitarium will be simple health education and experience. Health guests will be encouraged to put full faith in Jesus for healing.</span>
                    </p>
                    <p>
                        <span class="number grn">2</span>
                        <span class="txt">Primarily this is a gospel/benevolent work, therefore a set fee will not be required. Guest are invited to give a donation of their choice, according to individual means, which will be accepted with gratitude.</span>
                    </p>
                    <p>
                        <span class="number grn">3</span>
                        <span class="txt">The center will run continously to meet the timing needs of each health guest. There will not be set program dates.</span>
                    </p>
                    <p>
                        <span class="number grn">4</span>
                        <span class="txt">Health education, implementation of NEWSTART in daily life, spiritual education leading to revival & reformation, and salvation-topics such as the Creator God, physical & spiritual healing, salvation, how to have a relationship with God, prayer, etc. will be the emphasis of the program.</span>
                    </p>
                </div>
            </div>
        </app-panel>




        <!-- MAKER HEALS - WHY WE CHOSE -->
        <app-panel theme="lightGrey" class="standard-section retreat-model">
            <div class="wrapper">
                <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" style="margin: auto;" alt="The maker Heals" class="mh-logo">
                <h1 class="depature-h1">A <span class="grn">Departure</span> from <span class="grn">Traditional</span> Health Retreat Models.</h1>
                <p>The Maker Heals may be seen as a radical departure from Health Retreat Models typically seen in Australia.</p>
                <p>Christ the Creator, and harmony with Him is upheld as the first and most important aspect of healing. We seek not only to educate in God's health laws, but to acknowledge and direct individuals to the Creator as the One who heals.</p>
                <p>Read the "FAQ & Program Details" section for more on how we would market this program.</p>
                <p>The Maker Heals is less staff-intensive. It does not require treatments such as massage, hydrotherapy, herbal remedies, etc. This is unique. This enables the Madison Mission team, staff & volunteers in 2020 to continue to focus on industry/business
                    growth.
                </p>
            </div>
        </app-panel>



        <!-- HEALTH INSTITUTE QUOTE  -->
        <app-fullscreen-image class="quote1 quote2" src="https://cdn2.tda.website/beehive/20200608/AdobeStock-62929010.jpeg?fp-x=0.58&fp-y=0.49&fp-z=1.1&q=36&crop=entropy&h=1080&w=1920&fit=crop&auto=format">
            <div class="content">
                <div class="inner">
                    <img src="https://cdn2.tda.website/beehive/20200607/Quote.svg">
                    <div class="txt">
                        <div class="main">
                            <p>
                                The great object of this institution should be to improve the health of the body, that the afflicted may more highly appreciate eternal things. If this object is not continually set before the mind and efforts are not made to this end, it will prove a
                                curse instead of a blessing, spirituality will be regarded as a secondary thing, and the health of the body and diversion will be made primary.
                            </p>
                            <p>I saw that the high standard should not be lowered in the least in order that the institution may be patronized by unbelievers.</p>
                        </div>
                        <div class="reference">Testimonies Volume 1, pg 564 <br> <i>Written in 1867 to The Health Institute at Battle Creek</i> </div>
                    </div>
                </div>
            </div>
        </app-fullscreen-image>



        <!-- MAKER HEALS - WHY WE CHOSE -->
        <app-panel class="standard-section retreat-model">
            <div class="wrapper">
                <h1 style="text-align: center;">Why We Chose <br> <span class="grn">The Maker Heals</span></h1>
                <div class="health-section-points">
                    <p>
                        <span class="number grn">1</span>
                        <span class="txt">The means of healing, and the success of the whole venture is in God's hands, entirely. We cannot humanly guarantee a certain outcome as much as we may be able to with other retreat models. Therefore it is a matter of faith. The healing outcome for every guest is determined at a spiritual level, and therefore is in God's hands. We facilitate and seek to bring each individual to God, educate in God's health laws and lead to life revival and reformation.</span>
                    </p>
                    <p>
                        <span class="number grn">2</span>
                        <span class="txt">Simplicity. God's ways are simple. Usually the most simple solutions are the correct ones.</span>
                    </p>
                    <p>
                        <span class="number grn">3</span>
                        <span class="txt">Availability of trained facilitator from Bethel Sanitarium (Korea) to spear-head the program and train our staff.</span>
                    </p>
                    <p>
                        <span class="number grn">4</span>
                        <span class="txt">Can help many of our fellow Seventh-day Adventist brethren and lead to revival and reformation.</span>
                    </p>
                    <p>
                        <span class="number grn">5</span>
                        <span class="txt">High success in other countries where this model is applied. High spiritual success with many more baptisms of non-SDAs than other health centre models.</span>
                    </p>
                    <p>
                        <span class="number grn">6</span>
                        <span class="txt">Many incredible life-changing testimonies from those who have experienced this program in other countries, including full recovery from very serious life-threatening diseases. Stories, the likes of which we rarely hear about in retreats here in Australia.</span>
                    </p>
                    <p>
                        <span class="number grn">7</span>
                        <span class="txt">Low resource-intensive. Enables additional focus on other parts of the vision.</span>
                    </p>
                </div>
            </div>
        </app-panel>


        <!-- MAKER HEALS - FAQ -->
        <app-panel theme="lightGrey" class="standard-section">
            <div class="wrapper">
                <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The maker Heals" class="mh-logo">
                <h1 style="text-align: center;"><span class="grn">FAQ &amp; Program</span> Details</h1>
                <p><strong>Why change?</strong></p>
                <p>We plan to try a different approach in financing a health retreat, using new industries as income streams instead of relying heavily on donations. This could enable new possibilities for health ministry. Until we stabilize these industries,
                    we will run a simpler health retreat model.</p>

                <p><strong>What will happen to Abide Wellness Retreat?</strong></p>
                <p>Abide Wellness Retreat will remain closed, while we implement "The Maker Heals". The next steps will become clearer as we move forward, and as providence leads. </p>

                <p><strong>Did COVID-19 change your plans?</strong></p>
                <p>Yes. COVID changed a lot of things. We now can focus on starting industries and implementing "The Maker Heals".</p>

                <p><strong>But it's not wrong to charge people? <br>
Why donation basis?</strong></p>
                <p>Yes. It is not wrong to charge people. It is also not wrong to run a benevolent ministry. We see that both have a place and can potentially be successful. Donation basis allows those who can afford to pay, to do so if they wish, and those
                    who can't afford much, to still be ministered to and helped.</p>

                <p><strong>So are you only helping Adventists or Christians? <br>
    How is this marketed to the non-Churched?</strong></p>
                <p>Where implemented, this model has resulted in significantly more church growth than other models. It works because of God's power to heal. As Adventists find healing and restoration, non-Adventists come seeking the same. Marketing is primarily
                    word-of-mouth, based on testimonies of attendees/guests. Growth is organic and natural.</p>

                <p><strong>Who will be leading "The Maker Heals"?</strong></p>
                <p>The programs will be facilitated by Joy Cho, assisted by Emmanuel Higgins. <br> Joy is associated with the Bethel Sanitarium in South Korea. Bethel Sanitarium has succeeded in reaching people for Christ and bringing healing for more than
                    30 years. Joy has trained with Mrs. Choi Cha Soon, the founder of Bethel Sanitarium. Emmanuel has several years experience working in health retreats here in Australia.</p>

                <div class="imgs">
                    <img src="https://cdn2.tda.website/beehive/20200608/Joy-Cho.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.1&con=27&auto=format" alt="Joy Cho">
                    <img src="https://cdn2.tda.website/beehive/20200608/Emmanuel-Higgins.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.4&bri=11&auto=format" alt="Emmanuel Higgins">
                </div>


            </div>
        </app-panel>

        <app-subscribe></app-subscribe>
        <div class="section-div"></div>

        <!-- SCHOOL -->
        <app-panel id="school" class="school">

            <div class="wrapper">

                <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison-logo">

                <h1><span class="grn">Training</span> School</h1>

                <p style="max-width: 800px; text-align: left;"><strong>AIM:</strong> Accept an individual fresh from high-school. Send them out after several years, equipped for self-supporting success, financially & spiritually.</p>

                <p>What can we learn from those who have gone before us?</p>

                <div class="college madison">
                    <div class="a">
                        <p class="heading">Madison College</p>
                        <p>Hugely successful program from 1904 - 1964. Motto of "Skills, Knowledge, Character". Big emphasis on self-supporting industry. Trained youth for success. Success in ministry and industry.</p>
                        <p>Wholistic skills, Bible, health, medical training plus training in many industries.</p>
                    </div>
                    <div class="b">
                        <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-142054.jpg?h=315&w=500&fit=crop&auto=format" alt="">
                    </div>
                    <div class="a">
                        <p class="heading">ARISE Australia</p>
                        <p>Successful discipleship and training program based in Kingscliffe, NNSW. Conference endorsed with ministry career pathway.</p>
                        <p>Bible-worker training.</p>
                    </div>
                    <div class="b">
                        <p class="heading">Other Australian Bible Colleges</p>
                        <p>It Is Written Evangelism College, Eastward Missionary College, Cannot Be Hidden College, Highwood College. Each successful for a time.</p>
                        <p>Trained in Gospel & Health evangelism.</p>
                    </div>
                </div>
            </div>

            <div class="learning">
                <h2>Learning from their success and failure.</h2>
                <div class="college box">
                    <div class="a">
                        <p class="heading">They succeed when:</p>
                        <div class="school-section-points">
                            <p><span class="number">&bull;</span><span class="txt">Provided a career pathway</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Had an independent financial base OR successful industries</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Were successful in evangelism</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Owned facilities</span></p>
                        </div>
                    </div>
                    <div class="b">
                        <p class="heading">They failed when:</p>
                        <div class="school-section-points">
                            <p><span class="number">&bull;</span><span class="txt">Not attractive to youth</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Toxic church relationship</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Reliant on donations</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Inadequate or borrowed facilities</span></p>
                            <p><span class="number">&bull;</span><span class="txt">Unable to pay staff</span></p>
                        </div>
                    </div>
                </div>
            </div>

        </app-panel>


        <!-- PRAYER QUOTE  -->
        <app-fullscreen-image class="quote1" src="https://cdn2.tda.website/beehive/20200607/MC-2018-202-Prayer-8.jpg?fp-x=0.58&fp-y=0.49&fp-z=1.1&q=36&crop=focalpoint&h=1080&w=1920&fit=crop&auto=format">
            <div class="content">
                <div class="inner">
                    <img src="https://cdn2.tda.website/beehive/20200607/Quote.svg">
                    <div class="txt">
                        <div class="main">From the secret place of prayer came the power that shook the world...</div>
                        <div class="reference">Great Controversy 210</div>
                    </div>
                </div>
            </div>
        </app-fullscreen-image>



        <!-- MADISON SCHOOL -->
        <app-panel class="standard-section school">
            <div class="wrapper">
                <h1><span class="gr">Madison</span> College</h1>

                <h2 class="grn" style="margin: 30px 0px; font-size: 35px;">A Model Institution</h2>

                <p>This was the only self-supporting organisation on which Ellen White sat on the board.
                    <p>The 1930s were Madison's "Golden Age," a time of great prosperity and influence. The college thrived during the Depression, when many non-self-supporting colleges were forced to close their doors.</p>
                    <p>"Education and real life meet in this institution where students earn their own way to learning" - Readers Digest, 1938</p>
                    <p>Madison had 27 campus industries run by the students and teachers. Students left college with not one, but several industry skills.</p>
                    <p>Students worked 5 hours, and studied 5 hours every day.</p>
                    <p>Madison had 120 college and sanitarium buildings over 900-acres.</p>

                    <div class="grn-box">
                        <p class="heading">Self-employed Missionary</p>
                        <p>Imagine a college that trains youth to be self-employed missionaries.</p>
                        <p>The waldenses, apostles & Bible prophets did this.</p>
                    </div>
            </div>
        </app-panel>


        <!-- STUDENT PATHWAY -->
        <app-panel class="standard-section school" theme="lightGrey">
            <div class="wrapper" style="max-width: 900px;">
                <h1 style="text-align: center;">Student <span class="grn">Pathway</span></h1>

                <img src="https://cdn2.tda.website/beehive/20200608/Student-Blank.svg" alt="Student / User" class="pre-image" style="margin-top: 60px;">

                <img src="https://cdn2.tda.website/beehive/20200608/Student-Pathway.svg" alt="Arrow Down" class="pre-image">

                <div class="grid">
                    <div>
                        <h2>Learn Scripture</h2>
                        <img src="https://cdn2.tda.website/beehive/20200608/Student-School-Bible.svg" style="height: 100px;" alt="Bible">
                    </div>
                    <div>
                        <h2>Theory Classes</h2>
                        <p>Bible, Health, Christian Living, Music, Business, Accounting, & much more.</p>
                    </div>
                    <div>
                        <h2>Experience &amp; Learn <br> Two Industries</h2>
                        <img src="https://cdn2.tda.website/beehive/20200608/beehive-logo-dark-sq.svg" alt="Beehive Community Services">
                    </div>
                    <div>
                        <h2>Experience &amp; Learn <br> Health Ministry</h2>
                        <img src="https://cdn2.tda.website/beehive/20200608/Hands.svg" alt="The Maker Heals" class="makerheals">
                    </div>
                    <div>
                        <h2>Experience &amp; Learn <br> Youth Ministry &amp; Evangelism</h2>
                        <img src="https://cdn2.tda.website/beehive/20200607/Eastward-sq.svg" alt="Eastward Missions">
                    </div>
                    <div>
                        <h2>Experience &amp; Learn <br> Agriculture</h2>
                        <img src="https://cdn2.tda.website/beehive/20200608/Beehive-Farm-Circle.svg" alt="Beehive Farm">
                    </div>
                </div>

                <img src="https://cdn2.tda.website/beehive/20200607/Arrow-down-fat.svg" alt="Arrow Down" class="arrow-dwn ar1">

                <h2 class="ssm-header">Self-Supporting Missionary</h2>
                <img src="https://cdn2.tda.website/beehive/20200608/Matthew-5.svg" alt="Self-supporting Missionary" class="ssm-matt5">
            </div>
        </app-panel>
        <!-- EDUCATION SUMMARY -->
        <app-panel class="standard-section school">
            <div class="wrapper" style="max-width: 900px;">
                <h1><span class="grn">Education</span> Summary</h1>

                <p>A training facility for ages 16+. </p>
                <p>Implement inspired principles of education as exemplified in Madison College.</p>
                <p>Key components:</p>
                <div class="school-section-points tight">
                    <p><span class="number">&bull;</span><span class="txt">All Scripture subjects</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Personal spiritual life and practical godliness</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Health, physiology and healing</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Work / study program</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Industry practicum (in multiple industries)</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Literature evangelism practicum</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Health evangelism practicum</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Youth ministry practicum</span></p>
                </div>

                <p>The institution needs financially successful industries for students to replicate after they graduate.</p>

                <p>Students leaving the college should be:</p>

                <div class="school-section-points tight">
                    <p><span class="number">&bull;</span><span class="txt">Spiritually converted</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Scripturally grounded</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Financially successful</span></p>
                    <p><span class="number">&bull;</span><span class="txt">Passionate for ministry</span></p>
                </div>

                <p>We want to give students a way to achieve financial success without a university degree, and with an option of self-employment.</p>

                <p>Students need to be employable based on portfolio, experience or product, rather than a university degree. This rules out some industries (medical, accounting, etc.). But leaves many other possibilities: IT, media, web, agriculture, sales,
                    etc.
                </p>

                <p>The unique selling point will be training entrepreneurs and sole traders. This is a niche that doesn't compete with universities and existing colleges.</p>

                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-150818.jpg" alt="Beehive Industries" style="width: 100%;
                height: auto;">

                <div class="grn-box">
                    <p class="heading">Franchise - Beehive Community Services</p>
                    <p>Students would have a career pathway to operating their own business under the Beehive Community Services Franchise. Students leaving the school are then supported with a franchise package to open their own business. The business would
                        not only be focussed on financially supporting the individual, but also providing ministry leads and contacts. The franchise line should be reasonably low-capital investment.</p>
                </div>

                <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive" style="width: 250px; height: auto; margin: 60px auto; display: block;">

            </div>
        </app-panel>
        <app-subscribe></app-subscribe>
        <app-share></app-share>
        <div class="section-div"></div>

        <!-- EVANGELISM CENTRE -->

        <app-panel id="evangelism" theme="lightGrey" class="standard-section">
            <div class="wrapper">
                <h1><span class="grn">Evangelism</span> Centre</h1>
                <p>The Eastward team have been running youth-led literature evangelism programs right around Australia since 2014. We have distributed more than 60,000 books, and published a vegan cookbook and an edition of The Great Controversy. Thousands
                    of each have been distributed.</p>
                <p>The facility in Narbethong can become an incredible hub for this evangelistic work and enable it to expand in ways before impossible, both allowing us to publish new evangelistic books, and as a base for growing youth literature evangelism.</p>
                <p>In addition we seek to support churches & church plants in and around Melbourne with Beehive Services and The Maker Heals.</p>
                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-153252.jpg?h=700&w=960&fit=crop&auto=format" alt="">
                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-153315.jpg" alt="">

                <br>
                <p>We have developed software to help manage Bible work & Literature Evangelism.</p>
                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-153908.jpg" alt="">

                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-153924.jpg" alt="">

                <p>We have great interest in utilising technology and media for evangelism.</p>

                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-153937.jpg" alt="">

            </div>
        </app-panel>


        <div class="section-div"></div>

        <!-- YOUTH MINISTRY -->
        <app-panel id="youth" class="standard-section">

            <div class="wrapper">
                <h1><span class="grn">Youth</span> Ministry</h1>

                <p>The Music Camps & D'Sozo Camps have touched the hearts of young people from around Australia & New Zealand.</p>

                <p>Over time we will need to develop further bunkhouse and amenity facilities to cater for larger youth groups.</p>

                <div class="grid">
                    <div class="a">
                        <img src="https://cdn2.tda.website/beehive/20200608/MC-2018-170-Near-To-The-Heart-of-God-BTS-3.jpg?crop=faces&h=333&w=250&fit=crop&auto=format" alt="Young Person at Music Camp">
                    </div>
                    <div class="b">
                        <p><strong>...a complete change in my child!</strong></p>
                        <p>“The ministry you and your team are devoting yourselves to is indeed a life saving, life changing experience that teenagers/youth need... I am writing to thank you all for awakening a love for the Lord in my child. The joy I have
                            in my heart literally spilled out as tears of joy ... now [they are] wanting to be baptised" <br> <i>- A happy mother</i> </p>
                    </div>
                    <div class="a">
                        <p>"<strong>...I have waited a lifetime</strong> to experience such blessed richness of fellowship and music making to the glory of our God. ... A little foretaste of heaven!" <br>
                            <i>- Dr Don Roy</i></p>
                    </div>
                    <div class="b">
                        <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-154702.jpg?h=275&w=500&fit=crop&auto=format" alt="">
                    </div>
                    <div class="a">
                        <h2>
                            <a href="https://mus.camp/" target="_blank">www.mus.camp</a>
                        </h2>
                    </div>
                    <div class="b">
                        <h2>
                            <a href="https://dsozo.camp/" target="_blank">www.Dsozo.camp</a>
                        </h2>
                    </div>
                </div>
            </div>

        </app-panel>

        <img src="https://cdn2.tda.website/beehive/20200608/Music-Camp-Garden-Panorama.jpg?fp-x=0.55&fp-y=0.54&crop=focalpoint&h=500&w=1920&fit=crop&auto=format" class="panel">

        <app-panel>
            <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-155218.jpg?h=1100&w=1000&fit=crop&auto=format" class="panel">
        </app-panel>


        <div class="section-div"></div>

        <!-- AGRICULTURE HUB -->
        <app-panel id="agriculture" class="standard-section">

            <div class="wrapper">
                <h1><span class="grn">Agriculture</span> Hub</h1>

                <img src="https://cdn2.tda.website/beehive/20200608/Cultivate-FB-Cover-Final.jpg?h=628&w=1200&fit=crop&auto=format" class="panel">

                <p>Since 2017, Cultivate Conference (under Eastward) has brought together Adventist Agriculturalists from accross the pacific, and networked with the Adventist Agricultural Association in the United States.</p>
                <p>Our facility at Narbethong is ideally situated to be an agriculture base for Cultivate, with ample space and facilities. We look forward to hosting the next Cultivate Conference here and continuing to support Agriculture development and
                    education.
                </p>
                <p>Agriculture will be an important part of the Madison Mission student experience.</p>

                <h2><a href="https://www.cultivateconference.com.au/" target="_blank">www.cultivateconference.com.au</a></h2>

                <img src="https://cdn2.tda.website/beehive/20200608/Cultivate-2020-Banner.jpg?h=890&w=1920&fit=crop&auto=format" class="panel">

                <p>Our mission is to encourage and nurture young people, families, gardeners and farmers to grow into God's given plan for agriculture through networking, mentoring, and information sharing. </p>

                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-160859.jpg?h=602&w=900&fit=crop&auto=format" class="panel">

            </div>

        </app-panel>

        <div class="section-div"></div>

        <!-- MEET THE TEAM -->
        <app-panel id="team" class="standard-section">
            <div class="wrapper">
                <h1><span class="grn">Introducing</span> <br> the <span class="grn">Team</span></h1>
                <div class="team-grid">
                    <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20200608/Emmanuel-Higgins.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=3.2&bri=13&con=10&auto=format">
                        <h2>Emmanuel Higgins</h2>
                        <p>President</p>
                    </div>
                    <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20200608/Kayla-Reay.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.4&auto=format">
                        <h2>Kayla Reay</h2>
                        <p>Secretary</p>
                    </div>
                    <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20220725/Paul-Pannekoek.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=7.4&auto=format">
                        <h2>Paul Pannekoek</h2>
                        <p>Treasurer</p>
                    </div>
                    <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20200607/IMG-24f21d5160593b61a3a66f174ec2c017-V.jpg?mask=corners&corner-radius=150,150,150,150&crop=focalpoint&h=250&w=250&fit=crop&fp-x=0.81&fp-y=0.53&fp-z=1.8&bri=11&con=15&auto=format">
                        <h2>Andy Hopkins</h2>
                        <p>Director</p>
                    </div>
                    <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20200607/IMG-c6a84e15922d8c1df60bf302e108e706-V.jpg?mask=corners&corner-radius=150,150,150,150&crop=focalpoint&h=250&w=250&fit=crop&fp-x=0.19&fp-y=0.56&fp-z=1.5&auto=format">
                        <h2>Tim Hopkins</h2>
                        <p>Director</p>
                    </div>
                    <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20210923/Harry2.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=4&auto=format">
                        <h2>Harry Cole</h2>
                        <p>Committee Member</p>
                    </div>
                    <!-- <div class="member">
                        <img src="https://cdn2.tda.website/beehive/20200608/IMG-20200406-164600.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.8&bri=10&con=36&high=-50&sat=-25&vib=36&auto=format" alt="">
                        <h2>Hamdessa Jarra</h2>
                        <p>Member</p>
                    </div> -->
                </div>
                <p>Madison Missions Australia Ltd. is owned and driven by young people with proven dedication and commitment for this work.</p>

                <p>We have spent several years together in part-time and full-time gospel, literature and youth ministry with Eastward Missions.</p>

                <p>Tim, Andy and Emmanuel have worked together since 2013, pioneering Magabook literature evangelism in Australia and unique youth programs.</p>

                <img src="https://cdn2.tda.website/beehive/20200608/le-advance-group-shot.jpg?shad=35&h=350&w=850&fit=crop&auto=format" alt="Eastward Canvassing Team" class="panel">

                <img src="https://cdn2.tda.website/beehive/20200607/MC-2018-199-Group-Shot-3.jpg?shad=35&h=350&w=850&fit=crop&auto=format" alt="Eastward Music Camp Youth" class="panel">

                <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-163929.jpg?h=100&w=700&fit=crop&auto=format" style="margin: auto; display: block;" class="madison-eastward">
                <p style="text-align: center;">Madison Missions is officially endorsed and supported by Eastward.</p>

            </div>
        </app-panel>


        <!-- MADISON QUOTE  -->
        <app-fullscreen-image class="quote1" src="https://cdn2.tda.website/beehive/20200607/MC-2018-013-Group-Shot-Counsellors.jpg?fp-x=0.58&fp-y=0.49&fp-z=1.1&q=36&crop=faces&h=1080&w=1920&fit=crop&auto=format">
            <div class="content">
                <div class="inner">
                    <img src="https://cdn2.tda.website/beehive/20200607/Quote.svg">
                    <div class="txt">
                        <div class="main">We have an army of youth today who can do much if they are properly directed and encouraged... </div>
                        <div class="reference">- General Conference Bulletin, vol. 5, no. 2, p. 24</div>
                    </div>
                </div>
            </div>
        </app-fullscreen-image>


        <div class="section-div"></div>

        <app-panel id="happen" class="standard-section">

            <div class="wrapper">
                <h1>Let's <span class="grn">Make it Happen</span></h1>

                <p>Together we can raise up a new generation of self-supporting ministries.</p>

                <p>We're not a ministry that will always rely on donations. However we need special help in this phase.</p>

                <p>We are raising funds to purchase the Narbethong facility.</p>

                <p>Our need right now is to invest into our industries and raise the funds for a deposit.</p>

                <p>Your investment in Madison Missions will pay off as this vision becomes reality, <strong>a truly self-supporting institution, training self-supporting missionaries.</strong></p>

                <p>Madison is currently running a fundraising campaign at GoFundMe, for the purchase of the Abide Wellness Retreat (Highwood) facility. Please consider supporting this campaign.</p>

                <iframe class="gfm-embed-iframe" width="100%" height="499" frameborder="0" scrolling="no" src="https://www.gofundme.com/f/madison-missions-australia/widget/large/"></iframe>

                <p>&nbsp;</p>

                <p>Or make a general donation to Madison below:</p>

                <app-donate></app-donate>

                <br>
                <br>
                <h2>Or donate via bank deposit:</h2>
                <div class="grid">
                    <img src="https://cdn2.tda.website/beehive/20200608/2020-06-08-165115.jpg?h=342&w=500&fit=crop&auto=format" alt="">
                    <div class="txt">
                        <p>Account Name: Madison Missions Aust Ltd</p>
                        <p>BSB: 063 689</p>
                        <p>Account Number: 1028 0545</p>
                    </div>
                </div>
                <br>
                <br>
                <p>We seek your support in the form of a donation, interest-free loan or interest-bearing loan. <br> Donations are not tax-deductible at this time. If you require making a tax-deductible donation, please discuss this with us.</p>
                <br>
                <h2 style="font-size: 50px;">mail@madison.org.au</h2>
                <br>
                <h2 style="font-size: 50px;">0420 746 255</h2>

                <div class="logo-grid" style="margin-top: 50px;">
                    <div class="img">
                        <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison">
                    </div>
                    <div class="img">
                        <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" alt="Eastward Missions" class="eastward">
                    </div>
                    <div class="img">
                        <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The Maker Heals" class="makerheals">
                    </div>
                    <div class="img">
                        <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive">
                    </div>
                </div>

            </div>

        </app-panel>
    </div>
</div>
<app-share></app-share>
<app-subscribe></app-subscribe>