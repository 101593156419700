<section>
    <div class="flag">

    </div>
    <header>
        <h1>Ambassador Program</h1>
        <p>Together we can change the world.</p>
    </header>
    <div class="txt">
        <p><strong>We are looking for individuals who are:</strong></p>
        <ul>
            <li>Enthusiastic, motivated, *personally* passionate about the Madison Vision</li>
            <li>Seventh Day Adventists in good standing</li>
            <li>Willing and able to participate in sharing avenues (e.g. social media, verbal, written etc.)</li>
            <li>Perhaps have been personally impacted by or connected with Eastward's ministry in the past (youth camps, canvassing, outreach)</li>
        </ul>
        <p><strong>What's involved?</strong></p>
        <ul>
            <li>Not designed to be unrealistic or burdensome. Be involved as per your availability. It is a volunteer role.</li>
            <li>Help spread the word and raise awareness for the vision of a Madison-like missionary college. Some examples of what this could look like are:</li>
            <ul>
                <li>Local connections e.g. churches (videos, bulletin/verbal announcements)</li>
                <li>Online connections e.g. Social media (re-sharing, personal contacts), blogging, news outlets</li>
                <li>Events/camps/expo-booth</li>
                <li>Sharing Madison Missions resources</li>
            </ul>
            <li>Commit to initial online interview/briefing session help you feel well-informed, and then regular touch-base (e.g. fortnightly, monthly) with Madison Team Member; report ongoing activity, follow through with team leader requests/agreements
            </li>
            <li>Maintaining communication through emails</li>
            <li>Speaking well of team &amp; vision</li>
        </ul>
        <p><strong>Why become an ambassador?</strong></p>
        <ul>
            <li>Share in the mission. Make a difference. Help make it happen in a very real way! </li>

            <li>Free Eastward/Madison resources sent to you:</li>
            <ul>
                <li>x2 Great Controversy Magabooks</li>
                <li>x1 Wholesome Homemade Magabooks</li>
                <li>x12 Professionally printed Madison Vision Portfolio (38 pages, A4, full-colour, gloss)</li>
                <li>x1 Madison T-shirt (coming end of Dec)</li>
            </ul>
            <li>Simple training tools e.g. reference guide/handbook for ambassadors e.g. refer to, content guidelines (ie. using a specific custom hashtag on every post can help us keep track of social media results)</li>
            <li>Networking experience</li>
            <li>Personal meetings with Madison Team Members, ask any questions, feel up to date and knowledgeable, mentoring, support, guidance, what's working, what's not, hear your ideas</li>
            <li>Be one of the first to hear latest updates, exclusive info (special mailing list for ambassadors with news, campaign updates and tips). Special facebook group.</li>
        </ul>
        <div style="text-align: center;">
            <p><br></p>
            <p><strong>Would you like to be an Ambassador?</strong> Apply online.</p>
            <p></p>
            <p>
                <a href="https://madisonmissions.typeform.com/to/xtVda0A5" target="_blank" mat-flat-button color="primary">APPLY</a>
            </p>
        </div>
    </div>
    <img class="resources" src="https://cdn2.tda.website/beehive/20201126/AmbassadorMaterials.jpg?w=2000" alt="">
</section>