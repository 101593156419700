import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountPaymentSourcesComponent } from './components/account/account-payment-sources/account-payment-sources.component';
import { AccountPersonalDetailsComponent } from './components/account/account-personal-details/account-personal-details.component';
import { AccountComponent } from './components/account/account.component';
import { OpenNewsPostComponent } from './components/open-news-post/open-news-post.component';
import { OpenPostImageComponent } from './components/open-post-image/open-post-image.component';
import { ActivityStreamComponent } from './screens/activity-stream/activity-stream.component';
import { AllPostsComponent } from './screens/all-posts/all-posts.component';
import { AmbassadorsComponent } from './screens/ambassadors/ambassadors.component';
import { LoginComponent } from './screens/auth/login/login.component';
import { RecoverPwdComponent } from './screens/auth/recover-pwd/recover-pwd.component';
import { ResetPwdComponent } from './screens/auth/reset-pwd/reset-pwd.component';
import { SignupComponent } from './screens/auth/signup/signup.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
import { DonationReceiptComponent } from './screens/donation-receipt/donation-receipt.component';
import { LightOnAHillCampMeetingComponent } from './screens/events/light-on-a-hill-camp-meeting/light-on-a-hill-camp-meeting.component';
import { HomeComponent } from './screens/home/home.component';
import { MoreBusinessInfoComponent } from './screens/more-business-info/more-business-info.component';
import { MyNotificationsComponent } from './screens/my-notifications/my-notifications.component';
import { Page404Component } from './screens/page404/page404.component';
import { PrivacyPolicyComponent } from './screens/privacy-policy/privacy-policy.component';
import { SupportComponent } from './screens/support/support.component';
import { ViewPostComponent } from './screens/view-post/view-post.component';
import { VisionComponent } from './screens/vision/vision.component';
import { ZoomQaComponent } from './screens/zoom-qa/zoom-qa.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'news-post/:id',
        component: OpenNewsPostComponent,
        children: [
          {
            path: 'image',
            component: OpenPostImageComponent,
            outlet: 'imageRouter',
          },
        ],
      },
    ],
  },

  {
    path: 'vision',
    component: VisionComponent,
  },
  { path: 'business', component: MoreBusinessInfoComponent },
  { path: 'view-donation/:id', component: DonationReceiptComponent },
  { path: 'Ambassadors', component: AmbassadorsComponent },
  {
    path: 'stream',
    component: ActivityStreamComponent,
    children: [
      {
        path: 'news-post/:id',
        component: OpenNewsPostComponent,
        children: [
          {
            path: 'image',
            component: OpenPostImageComponent,
            outlet: 'imageRouter',
          },
        ],
      },
    ],
  },
  { path: 'all-posts', component: AllPostsComponent },
  { path: 'view-post/:id', component: ViewPostComponent },

  { path: 'Light-on-a-Hill-Camp-Meeting', component: LightOnAHillCampMeetingComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'zoom-q-and-a', component: ZoomQaComponent },
  { path: 'support', component: SupportComponent },
  {
    path: 'my-account',
    component: AccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-notifications',
    component: MyNotificationsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/all-payment-sources',
    component: AccountPaymentSourcesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/edit-details',
    component: AccountPersonalDetailsComponent,
    canActivate: [AuthGuardService],
  },

  { path: 'login', component: LoginComponent },
  { path: 'recover-pwd', component: RecoverPwdComponent },
  { path: 'reset-pwd', component: ResetPwdComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'pp', component: PrivacyPolicyComponent },

  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
