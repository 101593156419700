
<app-panel id="activityStream" class="mat-elevation-z5">
    <div class="inner">
        <img src="https://cdn2.tda.website/beehive/20200902/wyCgDmkx-Madison-Infographic-4x.png?h=550&w=550&fit=crop&auto=format" alt="">
        <div class="txt">
            <h1>Activity<br><span>Stream</span></h1>
            <p>What's happening, as it happens.</p>
            <button mat-stroked-button (click)="scrollToEl('stream', true)">See Activity</button> &nbsp;
            <button mat-stroked-button (click)="notifications()">
                <mat-icon>notifications</mat-icon>
                Get Notifications
            </button>
        </div>
    </div>
</app-panel>

<div style="background-color: #F0F2F5;">
    <app-news id="stream"></app-news>
</div>