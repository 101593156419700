import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { WINDOW } from 'src/app/services/window.service';
import * as Hls from 'hls.js';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { WatchStoryComponent } from 'src/app/dialogs/watch-story/watch-story.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('video', { static: false }) videoEl!: ElementRef;

  constructor(
    @Inject(WINDOW) private window: Window,
    private dialog: MatDialog,
    private utils: UtilsService,
  ) { }

  ngAfterViewInit(): void {
    const VideoEl = this.videoEl.nativeElement as HTMLVideoElement;
    VideoEl.autoplay = true;
    VideoEl.muted = true;
    VideoEl.loop = true;
    VideoEl.setAttribute('playsinline', 'true');
    const playbackId = 'm02Y00qsVhBwxrmaaoxnNeyR01Ym2a02g9GEoGTe4PBtuN8';
    VideoEl.style.backgroundImage = `url('https://image.mux.com/${playbackId}/thumbnail.jpg?width=1280&time=0')`;
    const url = `https://stream.mux.com/${playbackId}.m3u8`;
    // Let native HLS support handle it if possible
    if (VideoEl.canPlayType && VideoEl.canPlayType('application/vnd.apple.mpegurl')) {
      VideoEl.src = url;
      VideoEl.play();
    } else if (Hls.isSupported && Hls.isSupported()) {
      // HLS.js-specific setup code
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(VideoEl);
      VideoEl.play();
    }
  }

  volunteerApp(): void {
    this.window.open(`https://madisonmissions.typeform.com/to/uQ9VggpO`);
  }

  watchStory(): void {
    this.dialog.open(WatchStoryComponent, {
      width: '95%',
    });
  }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Madison Missions Australia - Establishing a Madison College',
      Title: 'Madison Missions Australia',
      Description: 'Madison is implementing a balanced approach to ministry with a revolutionary health program, supporting businesses, youth ministry, literature evangelism and more. Check out our website for the full vision, activities & plans.',
    });
  }

  open(link: string): void {
    this.window.open(link);
  }

}
