<div id="zoom-meeting">
    <h1>Zoom Q&A Session Details</h1>
    <div class="logo-grid">
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" alt="Madison Missions Australia Ltd." class="madison">
        </div>
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" alt="Eastward Missions" class="eastward">
        </div>
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/The-Maker-Heals-Logo.svg" alt="The Maker Heals" class="makerheals">
        </div>
        <div class="img">
            <img src="https://cdn2.tda.website/beehive/20200607/Beehive-Community-Services-Logo.svg" alt="Beehive Community Services" class="beehive">
        </div>
    </div>
    <br>
    <br>
    <img src="https://cdn2.tda.website/beehive/20200726/Zoom-Meeting-2x.png?h=340&w=500&fit=crop&auto=format" alt="">

    <p>Your questions will be answered by one or more team members of Eastward & Madison Missions, and The Maker Heals health program.</p>

    <h2>Meeting Details</h2>

    <p>Topic: Live Q&A
        <br>Time: Jul 30, 2020 06:30 PM Canberra, Melbourne, Sydney
        <br>
        <br>Join Zoom Meeting
        <br><a href="https://zoom.us/j/94676733104?pwd=K2ZIam53MlNHU3R2Zzk2SWlTS3padz09">https://zoom.us/j/94676733104?pwd=K2ZIam53MlNHU3R2Zzk2SWlTS3padz09</a>
        <br>
        <br>Meeting ID: 946 7673 3104
        <br>Passcode: 4576
        <br>One tap mobile
        <br>+61370182005,,94676733104# Australia
        <br>+61731853730,,94676733104# Australia
        <br>
        <br>Dial by your location
        <br>        +61 3 7018 2005 Australia
        <br>        +61 731 853 730 Australia
        <br>        +61 861 193 900 Australia
        <br>        +61 8 7150 1149 Australia
        <br>        +61 2 8015 6011 Australia
        <br>Meeting ID: 946 7673 3104
        <br>Find your local number: https://zoom.us/u/ag4yfMGjv
        </p>
</div>