import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, Event, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from 'src/app/services/window.service';
import { UtilsService } from 'src/app/services/utils.service';

interface VisionNav {
  label: string;
  anchor: string;
  el?: HTMLElement | null;
  offset?: number;
  linkOffset?: number;
  linkEl?: HTMLElement | null;
}

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})
export class VisionComponent implements OnInit, OnDestroy, AfterViewInit {

  visionNav: VisionNav[] = [
    { label: `Vision`, anchor: 'vision' },
    { label: `Business`, anchor: 'business' },
    { label: `Health Ministry`, anchor: 'health' },
    { label: `School`, anchor: 'school' },
    { label: `Evangelism`, anchor: 'evangelism' },
    { label: `Youth Ministry`, anchor: 'youth' },
    { label: `Agriculture`, anchor: 'agriculture' },
    { label: `Meet the Team`, anchor: 'team' },
    { label: `Let's Make\nit Happen`, anchor: 'happen' },
  ];

  sectionDistances: { [key: string]: number } = {};

  pill: HTMLDivElement | null = null;

  visionNavOpen = false;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private utils: UtilsService,
  ) {
    router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof NavigationEnd)
    ).subscribe((e: RouterEvent) => {
      if (e.url.includes('#')) {
        this.scrollToEl(e.url.split('#')[1]);
      }
    });
    if (this.window.addEventListener) {
      this.window.addEventListener('scroll', this.onScroll);
    }
  }

  scrollTop(): void {
    if (this.window.scrollTo) {
      this.window.scrollTo(0, 0);
    }
  }

  scrollToEl(id: string, smooth: boolean = false): void {
    const el = this.document.getElementById(id) as HTMLElement;
    if (this.window.scrollTo) {
      this.window.scrollTo({
        top: el.offsetTop,
        behavior: smooth ? 'smooth' : 'auto',
      });
    }
  }

  scrollToTop(): void {
    if (this.window.scrollTo) {
      this.window.scrollTo({ top: 0 });
    }
  }

  openVisionNav(): void {
    this.visionNavOpen = !this.visionNavOpen;
  }

  onScroll = () => {
    const vh = Math.max(this.document.documentElement.clientHeight || 0, this.window.innerHeight || 0);
    if (this.visionNav) {

      let itm: VisionNav | null = null;

      this.visionNav.forEach(r => {
        const anchorElement = this.document.getElementById(r.anchor);
        r.el = anchorElement;
        if (r.el !== null) {
          r.offset = r.el.offsetTop;
        }
        r.linkEl = r.linkEl || this.document.getElementById('link-' + r.anchor);
        if (r.linkEl !== null) {
          r.linkOffset = r.linkEl.offsetTop;
        }
        const offset = typeof r.offset === 'undefined' ? 0 : r.offset;
        if (offset < this.window.scrollY + (vh / 2)) {
          itm = r;
        }
      });

      if (itm !== null) {

        const VisionNavItem = itm as VisionNav;

        if (VisionNavItem.linkEl !== null && typeof VisionNavItem.linkEl !== 'undefined') {

          const height = (VisionNavItem.linkEl.offsetHeight || 0);
          this.pill = this.pill || this.document.getElementById('vision-nav-pill') as HTMLDivElement;
          this.pill.style.transform = `translate(0, ${VisionNavItem.linkOffset || 0}px)`;
          this.pill.style.height = height + 'px';

          const vw = Math.max(this.document.documentElement.clientWidth || 0, this.window.innerWidth || 0);

          if (vw < 640) {
            this.pill.style.display = height ? '' : 'none';
          }
        }

      }

    }
  }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: `Our Vision - ${this.utils.orgTitle}`,
      Title: 'Our Vision',
      Description: `Our vision embraces a balanced approach to ministry centred around healing, education and industry.`,
    });
    this.scrollToTop();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this.window.removeEventListener) {
      this.window.removeEventListener('scroll', this.onScroll);
    }
  }

  navigate(link: VisionNav, smooth: boolean = false): void {
    this.router.navigate([], {
      fragment: link.anchor,
    });
    this.scrollToEl(link.anchor, smooth);
    this.visionNavOpen = false;
  }

  open(link: string): void {
    this.window.open(link);
  }

}
