import { Component, OnInit, Inject } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-more-business-info',
  templateUrl: './more-business-info.component.html',
  styleUrls: ['./more-business-info.component.scss']
})
export class MoreBusinessInfoComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window,
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: `More business info`,
      Title: `More business info`,
      Description: `Find out more about Beehive Community Services and the Roadhouse.`,
    });
  }

  open(link: string): void {
    this.window.open(link);
  }

}
