<div id="lightOnaHill">
    <img src="https://cdn2.tda.website/beehive/20201126/Light-on-a-Hill_1.svg" alt="Light on a Hill - Camp Meeting">
</div>

<div id="info-section" class="madison-theme">
    <header>
        <h1>Camp Meeting</h1>
        <div class="space"></div>
        <a mat-flat-button href="https://madisonmissions.typeform.com/to/FqED9S8l" target="_blank" color="primary">Register</a>
    </header>
    <mat-divider></mat-divider>
    <div class="event-details">
        <div class="date">
            <mat-icon>event</mat-icon>
            <div class="txt">January 14 - 17</div>
        </div>
        <div class="location">
            <mat-icon>place</mat-icon>
            <div class="txt">Abide Wellness Retreat <br> 19 Westleys Rd <br> Faulkland NSW 2422</div>
        </div>
        <div class="location">
            <mat-icon>attach_money</mat-icon>
            <div class="txt">from $158/person*</div>
            <div class="txt"><small>(*dorm accommodation &amp; all meals)</small></div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="info">
        <div class="col">
            <h2>EXPERIENCE</h2>
            <p>Ministry & business in action <br> Interactive workshops <br> Inspiring messages & music</p>
        </div>
        <div class="col">
            <h2>TOPICS incl:</h2>
            <p>Ministry in the End Times<br> Revival and Reformation<br> Health Ministry - & more</p>
        </div>
        <div class="col">
            <h2>SPEAKERS incl:</h2>
            <p>
                Bruce Niyibitanga <br> Joy Cho <br> Emmanuel Higgins
            </p>
        </div>
    </div>
    <div><br></div>
    <h2>More Info</h2>
    <p>Let us not forsake the assembling of ourselves together even  more as we see the day approaching. This camp meeting will be a time of encouragement and revival as we seek God's will and unite in prayer.</p>
    <p>On-site accommodation is limited, but there is plenty of space for tents and caravans! There is also nearby accommodation options once on-campus facilities are full.</p>
    <p>Please share with your friends!</p>
    <div><br></div>
    <div class="action">
        <a mat-stroked-button href="https://madisonmissions.typeform.com/to/FqED9S8l" target="_blank">Register Online</a> &nbsp; 
        <a mat-stroked-button routerLink="/contact">Contact Us</a>
    </div>
    <div><br></div>
    <div><br></div>
</div>