import { Component, OnInit, Inject, Input } from '@angular/core';
import { LoginDialogComponent } from 'src/app/dialogs/login-dialog/login-dialog.component';
import { WINDOW } from 'src/app/services/window.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { ChooseAvatarComponent } from 'src/app/dialogs/choose-avatar/choose-avatar.component';

@Component({
  selector: 'app-login-with-provider',
  templateUrl: './login-with-provider.component.html',
  styleUrls: ['./login-with-provider.component.scss']
})
export class LoginWithProviderComponent implements OnInit {

  authenticating = false;
  @Input() label = 'Login with';

  constructor(
    @Inject(WINDOW) private window: Window,
    private dialog: MatDialog,
    private utils: UtilsService,
    private auth: AuthService,
    private db: DbService,
  ) { }

  ngOnInit(): void {
  }

  openMadison(): void {
    this.dialog.open(LoginDialogComponent, {
      width: '600px',
    });
  }

  openFacebook(): void {
    this.openOauthProvider('fb_oauth_redirect', 'Facebook');
  }

  openGoogle(): void {
    this.openOauthProvider('g_oauth_redirect', 'Google');
  }

  openOauthProvider(path: string, title: string): void {

    const id = this.utils.uid();

    const x = this.window.innerWidth / 2;
    const y = this.window.innerHeight / 2;

    const width = 0.75 * this.window.innerWidth;
    const height = 0.75 * this.window.innerHeight;

    const ref = this.window.open(`https://db.beehive.services/${path}?ref=${id}`, `Login with ${title}`, `status=no,toolbar=no,menubar=no,
    width=${width},height=${height},top=${y - (height / 2)},left=${x - (width / 2)}`);

    const loop = setInterval(() => {
      if (ref !== null && ref.closed) {
        clearInterval(loop);
        this.checkAuthRef(id);
      }
    }, 1000);

  }

  async checkAuthRef(ref: string): Promise<void> {
    this.authenticating = true;
    const res = await this.db.q('startOauthSesssion', { ref }, true);
    if (res.Success) {
      localStorage.setItem('session', res.Data.SessionID);
      await this.auth.checkSession();
      this.db.AuthSession = this.auth.sessionObj;
      if (!this.auth.person?.Avatar) {
        this.pickAvatar();
      }
    }
    this.authenticating = false;
  }

  pickAvatar(): void {
    if (this.auth.authChecked) {
      const dialogRef = this.dialog.open(ChooseAvatarComponent, {
        width: '800px',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (typeof res === 'string' && this.auth.person !== null) {
          this.auth.person.Avatar = res;
          this.db.addSchemaKey('Person', 'Avatar');
          this.db.put('Person', this.auth.person);
        }
      });
    }
  }

}
