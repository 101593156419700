
export interface ThemeColor {
    hex: string;
    text: string;
}

export type themeColorKeys =
    'red' | 'beehive' | 'primary' | 'DarkRed' | 'charcoal' |
    'grey50' | 'grey30' | 'grey70' | 'white' | 'lightGrey' | 'lightGreyOrange' |
    'whitePrimary' | 'black' | 'transparent';

export interface ThemeColor {
    hex: string;
    text: string;
}

type ThemeColors = {
    [key in themeColorKeys]: ThemeColor;
};

export const ThemeColors: ThemeColors = {
    red: {
        hex: 'red',
        text: '#ffffff',
    },
    beehive: {
        hex: '#ffd45a',
        text: '#333333',
    },
    primary: {
        hex: '#f36e21',
        text: '#ffffff',
    },
    DarkRed: {
        hex: '#c90e34',
        text: '#ffffff',
    },
    charcoal: {
        hex: '#222427',
        text: '#ffffff',
    },
    grey50: {
        hex: '#555555',
        text: '#ffffff',
    },
    grey30: {
        hex: '#333333',
        text: '#ffffff',
    },
    grey70: {
        hex: '#707070',
        text: '#ffffff',
    },
    white: {
        hex: '#FFFFFF',
        text: '#00000',
    },
    lightGrey: {
        hex: '#f7f7f7',
        text: '#222222',
    },
    lightGreyOrange: {
        hex: '#f7f7f7',
        text: '#e05425',
    },
    whitePrimary: {
        hex: '#FFFFFF',
        text: '#DA1376',
    },
    black: {
        hex: '#000000',
        text: '#ffffff',
    },
    transparent: {
        hex: 'transparent',
        text: 'currentColor',
    },
};
