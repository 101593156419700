<!-- <div id="volunteer-application" class="mat-elevation-z6">
    <div class="txt">Volunteer opportunities <br> available</div><br>
    <button mat-button (click)="volunteerApp()">Apply Online</button>
</div> -->

<!-- WELCOME TO MADISON  -->
<div id="welcome" style="background:#222;">
    <video #video autoplay loop muted playsinline></video>
    <div class="outer-wrapper">
        <h2 class="madison">Madison Missions</h2>
        <div class="home-wrapper">
            <div class="main-top">
                <h1>A New Vision for Australian Mission</h1>
                <p>Establishing a 21st-century Madison College</p>
                <div class="btns2">
                    <app-button theme="transparent" (click)="watchStory()" label="Watch the Story"></app-button>
                    <!-- <app-button theme="transparent" (click)="open('https://gofundmadison.com')" label="Donate"></app-button> -->
                    <!-- <app-button theme="transparent" (click)="open('https://madisonmissions.typeform.com/to/lf2tm9')" label="Watch the Story"></app-button> -->
                </div>
                <div class="btns">
                    <button>
                    <img src="https://cdn2.tda.website/beehive/20201106/NFnpZaKC-Madison-logo-home.svg" title="Madison Missions Australia Ltd.">
                    </button>
                    <button>
                        <img src="https://cdn2.tda.website/beehive/20201106/XqpoZTON-Eastward-logo-home.svg" title="Eastward Missions">
                    </button>
                    <button>
                        <img src="https://cdn2.tda.website/beehive/20201106/qZOlDopt-Beehive-logo-home.svg" title="Beehive Community Services">
                    </button>
                    <button>
                        <img src="https://cdn2.tda.website/beehive/20201106/ggDWuGdP-Maker-Heals-logo-home.svg" title="The Maker Heals">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 
<div id="lightOnaHill">
    <div class="inner">
        <img src="https://cdn2.tda.website/beehive/20201126/Light-on-a-Hill_1.svg" alt="Light on a Hill - Camp Meeting">
        <div class="info mobile-hide">
            <div class="col">
                <h2>Camp Meeting</h2>
                <p>(COVID permitting)</p>

            </div>
            <div class="col">
                <div class="venue">Abide Wellness Retreat</div>
                <div class="dates">January 14-17</div>
            </div>
            <div class="col" style="padding-left: 40px;">
                Ministry & business in action <br> Interactive workshops <br> Inspiring messages & music
            </div>
        </div>
        <div class="action mobile-hide">
            <a mat-stroked-button routerLink="/Light-on-a-Hill-Camp-Meeting">More Information</a> &nbsp;
            <a mat-stroked-button href="https://madisonmissions.typeform.com/to/FqED9S8l" target="_blank">Register</a>
        </div>
    </div>
    <div class="mobile-info mobile-show">
        <div class="info">
            <div class="col">
                <h2>Camp Meeting</h2>
                <p>(COVID permitting)</p>

            </div>
            <div class="col">
                <div class="venue">Abide Wellness Retreat</div>
                <div class="dates">January 14-17</div>
            </div>
            <div class="col" style="padding-left: 40px;">
                Ministry & business in action <br> Interactive workshops <br> Inspiring messages & music
            </div>
        </div>
        <div class="action">
            <a mat-stroked-button routerLink="/Light-on-a-Hill-Camp-Meeting">More Information</a> &nbsp; 
            <a mat-stroked-button href="https://madisonmissions.typeform.com/to/FqED9S8l" target="_blank">Register</a>
        </div>
    </div>
</div> -->

<!-- <app-ambassador-program></app-ambassador-program> -->

<app-panel theme="lightGrey">
    <h1>Update from 2021 and beyond</h1>
    <p>Dear Friends,</p>
    <p>Unfortunately Madison was unable to enter the purchase contract for Abide Wellness Retreat. We will be updating various aspects of this website as time permits</p>
    <p>However, the vision is still alive and strong!</p>
    <p>We will continue to serve in the capacity that we are able through evangelism, youth ministry and health ministry.</p>
    <p>You can follow our main activities at <a href="https://www.eastward.edu.au">www.eastward.edu.au</a></p>
    <p>Thank you for your prayers and support.</p>
    <p>- The Madision Team</p>
</app-panel>

<app-panel theme="primary">
    <img ixImg path="beehive/20201117/XTVNPVZQ-MadisonPortfolioRender.png" style="max-width: 1000px; height: auto;" alt="">
    <div style="text-align: center;">
        <app-button theme="transparent" style="display: inline-block;" routerLink="/vision" label="Discover the Vision"></app-button>
    </div>
</app-panel>

<app-section theme="lightGreyOrange">
    <img ixImg path="beehive/20201117/Madison-updates-4x.png" style="max-width: 300px; display: block; margin: auto; height: 300px;" alt="">
    <div style="text-align: center;">
        <app-button theme="transparent" style="display: inline-block;" (click)="open('https://madisonmissions.typeform.com/to/hzful2Nj')" label="Join the Mailing List"></app-button>
    </div>
</app-section>

<app-activity-stream [embedded]="true"></app-activity-stream>