import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  siteTitle = 'Madison Missions';
  websiteDomain = 'www.madison.org.au';
  sitePhone1 = '0420 746 255';
  siteEmail = 'mail@madison.org.au';
  siteAddress = '19 Westleys Rd, Faulkland NSW 2422';

  constructor(
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Privacy Policy - The Maker Heals');
  }

}
