<div class="menu" [ngClass]="closed ? 'closed' : 'open'" (click)="toggleMenu($event)">
    <div class="wrap mat-elevation-z3">
    </div>
    <div class="content" [ngClass]="showContent">
        <h1>Menu</h1>
        <div class="inner">
            <nav>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/">Home</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/vision">Our Vision</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/stream">Activity Stream</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/support">Make a Gift</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/contact">Contact Us</a>
            <a matRipple href="tel:61420746255">
                    <app-icon>phone</app-icon>
                    <span>+61 0420 746 255</span>
                </a>
                <a matRipple href="mailto:mail@madison.org.au">
                    <app-icon>email</app-icon>
                    <span>mail@madison.org.au</span>
                </a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://makerheals.org.au/">The Maker Heals Program</a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://eastward.edu.au/" target="_blank">Eastward Missions</a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://beehive.services/" target="_blank">Beehive Community Services</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/my-account">My Account</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/pp">Privacy Policy</a>
            </nav>
            <div class="footer">
                &copy; Madison Missions Australia Ltd. | 19 Westleys Rd, Faulkland NSW 2422 | mail@madison.org.au | +61 420 746 255
            </div>
        </div>
    </div>
    <div class="burger" matRipple (click)="toggleMenu($event, true)">
        <div class="inner">
            <div class="l line1"></div>
            <div class="l line2"></div>
            <div class="l line3"></div>
        </div>
    </div>
</div>