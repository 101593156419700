import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { WINDOW } from 'src/app/services/window.service';
import { setTimeout$ } from 'src/app/services/DataStore';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  IsAuthenticated = false;
  closed = true;
  showContent = '';

  onAuthChange = (AuthState: boolean) => {
    this.IsAuthenticated = AuthState;
  }

  constructor(
    private authService: AuthService,
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
    this.authService.onStateChange(this.onAuthChange);
  }

  ngOnDestroy(): void {
    this.authService.removeStateChangeCallback(this.onAuthChange);
  }

  toggleMenu(event: MouseEvent, burger?: boolean): void {
    // if (this.window.scrollTo) {
    //   this.window.scrollTo(0, 0);
    // }
    event.stopPropagation();
    if (burger || this.closed) {
      this.closed = !this.closed;
      if (!this.closed) {
        setTimeout(() => {
          this.showContent = 'show';
        }, 80);
      } else {
        this.showContent = '';
      }
    }
  }

}
